import {advancedSearch, defaultDirection} from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const resetHistory = (e, link, category) => {
  const cat = (category && category) ? category : 0;
  let states = {search:'',currentPage: 1, fullSearch: advancedSearch,'category': cat,'resetForm':1,'brand':0, 'advSearch': {'category': cat}}


  const pages = (link)?link.split('/'):[];
  const typepage = (pages[2] && pages[2]!=='')?pages[2]:'start';
  localStorage.setItem('typepage', typepage);
  localStorage.setItem('state_start', JSON.stringify(states));
  localStorage.setItem('state_new', JSON.stringify(states));
  localStorage.setItem('state_stocks', JSON.stringify(states));
  localStorage.setItem('state_sale', JSON.stringify(states));
  localStorage.setItem('state_favorite', JSON.stringify(states));
  localStorage.setItem('state_exclusive', JSON.stringify(states));
};

export const getFind = (options, value) => {
    const res = options.filter(item => (item && item.value && value && item.value === value));
    return (res && res.length>0 && res[0].label)?res[0].label:"";
};

export const getShowForm = (options, value) => {
  const res = options.filter(item => (item && item.category && item.category === value.toString()));
  return (res && res.length>0 && res[0].show)?res[0].show:{};
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
};

export const toUrlSearch = (hipChatSettings) => {
    return Object.keys(hipChatSettings).map(function(prop) {
        return [prop, hipChatSettings[prop]].map(encodeURIComponent).join("=");
    }).join("&");
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

