import {
    FAVORITE_ADD,
    FAVORITE_ADD_ITEM_SUCCESS,
    FAVORITE_ADD_ITEM_ERROR,
    FAVORITE_GET_LIST,
    FAVORITE_GET_LIST_SUCCESS,
    FAVORITE_GET_LIST_ERROR,
    ACCOUNT_GET_INFO,
    ACCOUNT_GET_INFO_SUCCESS,
    ACCOUNT_GET_INFO_ERROR,
    CONFIG_GET,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_ERROR
} from '../actions';

const INIT_STATE = {
	favoriteItems:null,
    accountInfo:null,
    config:null,
	loading:false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

        case ACCOUNT_GET_INFO:
            return { ...state, loading: false };

        case ACCOUNT_GET_INFO_SUCCESS:
            return { ...state, loading: true, accountInfo: action.payload };

        case ACCOUNT_GET_INFO_ERROR:
            return { ...state, loading: true, error: action.payload };

        case CONFIG_GET:
            return { ...state, loading: false };

        case CONFIG_GET_SUCCESS:
            return { ...state, loading: true, config: action.payload };

        case CONFIG_GET_ERROR:
            return { ...state, loading: true, error: action.payload };

        case FAVORITE_GET_LIST:
            return { ...state, loading: false };

        case FAVORITE_GET_LIST_SUCCESS:
            return { ...state, loading: true, favoriteItems: action.payload };

        case FAVORITE_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };

        case FAVORITE_ADD:
            return { ...state, loading: true };

        case FAVORITE_ADD_ITEM_SUCCESS:
            return { ...state, loading: true };

        case FAVORITE_ADD_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload };


		default: return { ...state };
	}
};
