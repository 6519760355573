import {
  SPECIAL_GET_LIST,
  SPECIAL_GET_LIST_SUCCESS,
  SPECIAL_GET_LIST_ERROR
} from '../actions';

const INIT_STATE = {
	productItems:null,
	loading:false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

        case SPECIAL_GET_LIST:
            return { ...state, loading: false };

        case SPECIAL_GET_LIST_SUCCESS:
            return { ...state, loading: true, productItems: action.payload };

        case SPECIAL_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };

		default: return { ...state };
	}
}
