import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
    SPECIAL_GET_LIST,
    getAccountInfo
} from '../actions';

import {
    getSpecialListSuccess,
    getSpecialListError
} from './actions';
import axios from "axios";
import {apiPath} from "../../constants/defaultValues";
import {getConfig} from "../favorite/actions";
import {toUrlSearch} from "../../helpers/Utils";

const apiUrl = apiPath + "/json/product-special.do/";
const apiProductUrl = apiPath + "/json/product-page.do/";




const getSpecialListRequest = (pageSize, currentPage, orderBy, orderTypeBy, search, advSearch, type, exclusive) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
  const lang = localStorage.getItem('currentLanguage');
    let advSearchUrl = (advSearch)?toUrlSearch(advSearch):'';
    if(advSearchUrl!=='') advSearchUrl = '&'+advSearchUrl;
    const url = (type === 0) ? apiProductUrl : apiUrl;
    return axios({
        method: "get",
        url: `${url}?user=${user_id}&lang=${lang}&token=${token}&pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}&orderTypeBy=${orderTypeBy}&search=${search}&type=${type}&exclusive=${exclusive}${advSearchUrl}`
    });
};

function* getSpecialListItems({payload}) {
    try {
        const response = yield call(getSpecialListRequest, payload.pageSize, payload.currentPage, payload.orderBy, payload.orderTypeBy, payload.search, payload.advSearch, payload.type, payload.exclusive);
        yield put(getSpecialListSuccess(response));
        yield put(getConfig());
        yield put(getAccountInfo());
    } catch (error) {
        yield put(getSpecialListError(error));
    }
}

export function* watchGetSpecialList() {
    yield takeEvery(SPECIAL_GET_LIST, getSpecialListItems);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetSpecialList),
    ]);
}
