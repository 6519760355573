import {
    ORDERS_GET_LIST,
    ORDERS_GET_LIST_SUCCESS,
    ORDERS_GET_LIST_ERROR,
    ORDER_GET_INFO,
    ORDER_GET_INFO_SUCCESS,
    ORDER_GET_INFO_ERROR
} from '../actions'

const INIT_STATE = {
	ordersItems:null,
    orderInfo: null,
	loading:false
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {

        case ORDERS_GET_LIST:
            return { ...state, loading: false }

        case ORDERS_GET_LIST_SUCCESS:
            return { ...state, loading: true, ordersItems: action.payload }

        case ORDERS_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }

        case ORDER_GET_INFO:
            return { ...state, loading: false }

        case ORDER_GET_INFO_SUCCESS:
            return { ...state, loading: true, orderInfo: action.payload }

        case ORDER_GET_INFO_ERROR:
            return { ...state, loading: true, error: action.payload }

		default: return { ...state }
	}
}
