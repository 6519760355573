import {
    FAVORITE_GET_LIST,
    FAVORITE_ADD_ITEM_SUCCESS,
    FAVORITE_ADD_ITEM_ERROR,
    FAVORITE_GET_LIST_SUCCESS,
    FAVORITE_GET_LIST_ERROR,
    FAVORITE_ADD,
    ACCOUNT_GET_INFO,
    ACCOUNT_GET_INFO_SUCCESS,
    ACCOUNT_GET_INFO_ERROR,
    ACCOUNT_SAVE,
    ACCOUNT_SAVE_SUCCESS,
    ACCOUNT_SAVE_ERROR,
    CONFIG_GET,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_ERROR
} from '../actions';


export const doSaveAccount = (item) => ({
    type: ACCOUNT_SAVE,
    payload: {item}
});

export const doSaveAccountSuccess = (items) => ({
    type: ACCOUNT_SAVE_SUCCESS,
    payload: items
});

export const doSaveAccountError = (error) => ({
    type: ACCOUNT_SAVE_ERROR,
    payload: error
});

export const getAccountInfo = () => ({
    type: ACCOUNT_GET_INFO
});

export const getAccountInfoSuccess = (items) => ({
    type: ACCOUNT_GET_INFO_SUCCESS,
    payload: items
});

export const getAccountInfoError = (error) => ({
    type: ACCOUNT_GET_INFO_ERROR,
    payload: error
});


export const getConfig = () => ({
    type: CONFIG_GET
});

export const getConfigSuccess = (items) => ({
    type: CONFIG_GET_SUCCESS,
    payload: items
});

export const getConfigError = (error) => ({
    type: CONFIG_GET_ERROR,
    payload: error
});


export const getFavoriteList = (items) => ({
    type: FAVORITE_GET_LIST,
    payload: items
});

export const getFavoriteListSuccess = (items) => ({
    type: FAVORITE_GET_LIST_SUCCESS,
    payload: items
});

export const getFavoriteListError = (error) => ({
    type: FAVORITE_GET_LIST_ERROR,
    payload: error
});


export const addFavorite = (id,page) => ({
    type: FAVORITE_ADD,
    payload: {id,page}
});

export const addFavoriteItemSuccess = (items) => ({
    type: FAVORITE_ADD_ITEM_SUCCESS,
    payload: items
});

export const addFavoriteItemError = (error) => ({
    type: FAVORITE_ADD_ITEM_ERROR,
    payload: error
});
