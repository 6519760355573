import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
    BASKET_ADD,
    BASKET_GET_LIST,
    BASKET_REMOVE,
    BASKET_CHANGE,
    CHANGE_DISCOUNT,
    TO_ORDER_ADD,
    CHANGE_ALL_DISCOUNT, getAccountInfo, getOrderInfo, TO_BASKET_ADD
} from '../actions';

import {
    addBasketItemSuccess,
    addBasketItemError,
    getBasketListSuccess,
    getBasketListError,
    getBasketList,
    removeBasketItemSuccess,
    removeBasketItemError,
    changeBasketItemSuccess,
    changeBasketItemError,
    addToOrderItemSuccess,
    addToOrderItemError,
    changeDiscountError,
    changeDiscountSuccess,
    changeAllDiscountSuccess,
    changeAllDiscountError, addToBasketItemSuccess, addToBasketItemError
} from './actions';
import axios from "axios";
import {apiPath} from "../../constants/defaultValues";

const apiUrl = apiPath + "/json/product-add-basket.do/";
const apiChangeUrl = apiPath + "/json/product-change-basket.do/";
const apiDiscountUrl = apiPath + "/json/product-discount-basket.do/";
const apiAllDiscountUrl = apiPath + "/json/product-all-discount-basket.do/";
const apiRemoveUrl = apiPath + "/json/product-remove-basket.do/";
const apiBasketListUrl = apiPath + "/json/product-basket.do/";
const apiToOrderUrl = apiPath + "/json/product-to-order.do/";
const apiToBasketUrl  = apiPath + "/json/product-to-basket.do/";


const addBasketRequest = (item, quantity) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
  const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiUrl}?id=${item}&lang=${lang}&quantity=${quantity}&user=${user_id}&token=${token}`
    });
};

const removeBasketRequest = (item, orderId) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    return axios({
        method: "get",
        url: `${apiRemoveUrl}?id=${item}&orderId=${orderId}&quantity=1&user=${user_id}&token=${token}`
    });
};

const changeBasketRequest = (num, id, orderId) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    return axios({
        method: "get",
        url: `${apiChangeUrl}?id=${id}&quantity=${num}&orderId=${orderId}&user=${user_id}&token=${token}`
    });
};

const changeDiscountRequest = (num, id,orderId) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    return axios({
        method: "get",
        url: `${apiDiscountUrl}?id=${id}&discount=${num}&orderId=${orderId}&user=${user_id}&token=${token}`
    });
};
const changeAllDiscountRequest = (num) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    return axios({
        method: "get",
        url: `${apiAllDiscountUrl}?discount=${num}&user=${user_id}&token=${token}`
    });
};

function* addTodoItem({payload}) {
    try {
        const response = yield call(addBasketRequest, payload.item, payload.quantity);

        yield put(addBasketItemSuccess(response));
        yield put(getBasketList());
    } catch (error) {
        yield put(addBasketItemError(error));
    }
}

function* removeItem({payload}) {
    try {
        const response = yield call(removeBasketRequest, payload.item, payload.orderId);

        if (payload.orderId === undefined)
            yield put(getBasketList());
        else
            yield put(getOrderInfo(payload.orderId));
        yield put(removeBasketItemSuccess(response));
    } catch (error) {
        yield put(removeBasketItemError(error));
    }
}

function* changeBasketItem({payload}) {

    try {
        const response = yield call(changeBasketRequest, payload.num, payload.id, payload.orderId);
        if (payload.orderId === undefined)
            yield put(getBasketList());
        else
            yield put(getOrderInfo(payload.orderId));
        yield put(changeBasketItemSuccess(response));
    } catch (error) {
        yield put(changeBasketItemError(error));
    }
}

function* changeDiscountItem({payload}) {

    try {
        const response = yield call(changeDiscountRequest, payload.value, payload.id, payload.orderId);
        if (payload.orderId === undefined)
            yield put(getBasketList());
        else
            yield put(getOrderInfo(payload.orderId));
        yield put(changeDiscountSuccess(response));
    } catch (error) {
        yield put(changeDiscountError(error));
    }
}

function* changeAllDiscountItem({payload}) {

    try {
        const response = yield call(changeAllDiscountRequest, payload.value);
        yield put(getBasketList());
        yield put(changeAllDiscountSuccess(response));
    } catch (error) {
        yield put(changeAllDiscountError(error));
    }
}

const getBasketListRequest = () => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
  const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiBasketListUrl}?user=${user_id}&lang=${lang}&token=${token}`
    });
};

function* getBasketListItems() {
    try {
        const response = yield call(getBasketListRequest);
        yield put(getAccountInfo());
        yield put(getBasketListSuccess(response));
    } catch (error) {
        yield put(getBasketListError(error));
    }
}


const toOrderRequest = ({comment, name, save, order_id}) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiToOrderUrl}?comment=${comment}&lang=${lang}&name=${name}&save=${save}&user=${user_id}&order_id=${order_id}&token=${token}`
    });
};


function* toOrderItem({payload}) {
    try {
        const response = yield call(toOrderRequest, payload);
        yield put(addToOrderItemSuccess(response));
        yield put(getBasketList());
    } catch (error) {
        yield put(addToOrderItemError(error));
    }
}


const toBasketRequest = ({order_id}) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiToBasketUrl}?user=${user_id}&lang=${lang}&order_id=${order_id}&token=${token}`
    });
};

function* toBasketItem({payload}) {

    try {
        const response = yield call(toBasketRequest, payload);
        yield put(addToBasketItemSuccess(response));
        yield put(getBasketList());
    } catch (error) {
        yield put(addToBasketItemError(error));
    }
}


export function* watchGetList() {
    yield takeEvery(BASKET_GET_LIST, getBasketListItems);
}


export function* watchAddItem() {
    yield takeEvery(BASKET_ADD, addTodoItem);
}

export function* watchChangeDiscount() {
    yield takeEvery(CHANGE_DISCOUNT, changeDiscountItem);
}

export function* watchChangeAllDiscount() {
    yield takeEvery(CHANGE_ALL_DISCOUNT, changeAllDiscountItem);
}

export function* watchChangeItem() {
    yield takeEvery(BASKET_CHANGE, changeBasketItem);
}

export function* watchRemoveItem() {
    yield takeEvery(BASKET_REMOVE, removeItem);
}

export function* watchAddToOrderItem() {
    yield takeEvery(TO_ORDER_ADD, toOrderItem);
}

export function* watchAddToBasketItem() {
    yield takeEvery(TO_BASKET_ADD, toBasketItem);
}

export default function* rootSaga() {
    yield all([
        fork(watchAddItem),
        fork(watchGetList),
        fork(watchRemoveItem),
        fork(watchChangeItem),
        fork(watchChangeDiscount),
        fork(watchChangeAllDiscount),
        fork(watchAddToOrderItem),
        fork(watchAddToBasketItem),
    ]);
}
