import {
    CATALOGS_GET_LIST,
    CATALOGS_GET_LIST_SUCCESS,
    CATALOGS_GET_LIST_ERROR
} from '../actions'


export const getCatalogsList = (items) => ({
    type: CATALOGS_GET_LIST,
    payload: items
})

export const getCatalogsListSuccess = (items) => ({
    type: CATALOGS_GET_LIST_SUCCESS,
    payload: items
})

export const getCatalogsListError = (error) => ({
    type: CATALOGS_GET_LIST_ERROR,
    payload: error
})



