import {combineReducers} from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import basketApp from './basket/reducer';
import favoriteApp from './favorite/reducer';
import ordersApp from './orders/reducer';
import specialApp from './special/reducer';
import catalogsApp from './catalogs/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  basketApp,
  favoriteApp,
  ordersApp,
  specialApp,
  catalogsApp
});

export default reducers;
