
const i18n =require('roddeh-i18n');
export const setLanguage = (lang) => {

  if (lang === 'uk') {
    i18n.translator.reset();
    i18n.translator.add({
      "values": {
        "ru":"ua",
        "региональный склад": "регіональний склад",
        "удалить из избраного": "видалити з вибраного",
        "добавить в избраное": "додати в обране",
        "Свободный остаток": "Вільний залишок",
        "Резерв": "Резерв",
        "PPЦ поставщика": "PPЦ постачальника",
        "Онлайн РРЦ": "Онлайн РРЦ",
        "В дороге": "В дорозі",
        "Категория": "Категорія",
        "Скачать инструкцию": "Завантажити інструкцію",
        "без изображения":"без зображення",
        "С изображением":"З зображенням",
        "Заказ сохранен и отправлен в обработку":"Замовлення збережено і відправлений в обробку",
        "Заказ сохранен":"Замовлення збережено",
        "Произошла ошибка при сохранение корзины":"Сталася помилка при збереження кошика",
        "Заказ отправлен в обработку":"Замовлення відправлений в обробку",
        "Заказ был сохранен":"Замовлення було збережено",
        "Не удалось удалить товар из корзины":"Не вдалося видалити товар з кошика",
        "Товар удален из корзины":"Товар вилучений з кошика",
        "Товар добавлен в корзину":"Товар доданий в кошик",
        "Товар не удалось добавить в корзину":"Товар не вдалося додати в кошик",
        "Добавить в корзину":"Купити",
        "удалить":"вилучити",
        "Любой бренд":"Всі бренди",
        "Любая коллекция":'Всі колекції',
        "Любая функция":'Всі функції',
        "Любая установка":'Всі установки',
        "Любая форма":'Всі форми',
        "Любая материал":'Всі матеріали',
        "Любая специализация": 'Вся спеціалізація',
        'Любой цвет':'Всі кольори',
        "Любой статус":"Всі статуси",
        "Любая категория":"Всі категорії",

        "Любой тип":"Всі типи",
        "Любой елемент многофункционального душа":"Всі елемент багатофункціонального душа",
        "Любой тип саун":"Всі типи саун",
        "Любой тип ароматизации":"Всі типи ароматизації",
        "Любое применение":"Всі застосування",
        "Любая текстура":"Всі текстури",
        "Любой елемент":"Всі елементи",
        "Любое количество":"Будь-яка кількість",
        "Любой тип элемента":"Всі типи елемента",
        "Любой тип системы":"Всі типи системи",
        "Любая подкатегория":"Всі підкатегорії",
        "Любое название товара или артикул":"Будь-яка назва товару або артикул",
        "Искать...": "Шукати...",
        'Все склады': "Усі склади",
        'Киев': "Київ",
        'Тернополь': "Тернопіль",
        'Ужгород': "Ужгород",
        'Одесса': "Одеса",
        'Львов': "Львів",
        'Днепр': "Дніпро",
        "На складе": "На складі"
       }
    });
  }
  // Default Language (English)
  else {
    i18n.translator.reset();
    i18n.translator.add({
      "values":{

      }
    });
  }
}
