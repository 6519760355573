import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import basketSaga from './basket/saga';
import favoriteSaga from './favorite/saga';
import ordersSaga from './orders/saga';
import specialSaga from './special/saga';
import catalogsSaga from './catalogs/saga';

export default function* rootSaga(getState) {

  yield all([
    authSagas(),
    basketSaga(),
    favoriteSaga(),
    ordersSaga(),
    specialSaga(),
    catalogsSaga()
  ]);
}
