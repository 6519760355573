import {
  SPECIAL_GET_LIST,
  SPECIAL_GET_LIST_SUCCESS,
  SPECIAL_GET_LIST_ERROR
} from '../actions';


export const getSpecialList = (items) => ({
    type: SPECIAL_GET_LIST,
    payload: items
});

export const getSpecialListSuccess = (items) => ({
    type: SPECIAL_GET_LIST_SUCCESS,
    payload: items
});

export const getSpecialListError = (error) => ({
    type: SPECIAL_GET_LIST_ERROR,
    payload: error
});
