/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

export const BASKET_ADD = "BASKET_ADD";
export const BASKET_GET_LIST_SUCCESS = "BASKET_GET_LIST_SUCCESS";
export const BASKET_GET_LIST_ERROR = "BASKET_GET_LIST_ERROR";
export const BASKET_GET_LIST = "BASKET_GET_LIST";
export const BASKET_REMOVE = "BASKET_REMOVE";
export const BASKET_ADD_ITEM_ERROR = "BASKET_ADD_ITEM_ERROR";
export const BASKET_ADD_ITEM_SUCCESS = "BASKET_ADD_ITEM_SUCCESS";
export const BASKET_REMOVE_ITEM_SUCCESS = "BASKET_REMOVE_ITEM_SUCCESS";
export const BASKET_REMOVE_ITEM_ERROR = "BASKET_REMOVE_ITEM_ERROR";
export const BASKET_CHANGE = "BASKET_CHANGE";
export const BASKET_CHANGE_ITEM_SUCCESS = "BASKET_CHANGE_ITEM_SUCCESS";
export const BASKET_CHANGE_ITEM_ERROR = "BASKET_CHANGE_ITEM_ERROR";
export const TO_ORDER_ADD = "TO_ORDER_ADD";
export const TO_ORDER_ADD_ITEM_SUCCESS = "TO_ORDER_ADD_ITEM_SUCCESS";
export const TO_ORDER_ADD_ITEM_ERROR = "TO_ORDER_ADD_ITEM_ERROR";
export const TO_BASKET_ADD = "TO_BASKET_ADD";
export const TO_BASKET_ADD_ITEM_SUCCESS = "TO_BASKET_ADD_ITEM_SUCCESS";
export const TO_BASKET_ADD_ITEM_ERROR = "TO_BASKET_ADD_ITEM_ERROR";

export const CHANGE_DISCOUNT = "CHANGE_DISCOUNT";
export const CHANGE_DISCOUNT_SUCCESS = "CHANGE_DISCOUNT_SUCCESS";
export const CHANGE_DISCOUNT_ERROR = "CHANGE_DISCOUNT_ERROR";

export const CHANGE_ALL_DISCOUNT = "CHANGE_ALL_DISCOUNT";
export const CHANGE_ALL_DISCOUNT_SUCCESS = "CHANGE_ALL_DISCOUNT_SUCCESS";
export const CHANGE_ALL_DISCOUNT_ERROR = "CHANGE_ALL_DISCOUNT_ERROR";

export const FAVORITE_ADD = "FAVORITE_ADD";
export const FAVORITE_GET_LIST = "FAVORITE_GET_LIST";
export const FAVORITE_ADD_ITEM_SUCCESS = "FAVORITE_ADD_ITEM_SUCCESS";
export const FAVORITE_ADD_ITEM_ERROR = "FAVORITE_ADD_ITEM_ERROR";
export const FAVORITE_GET_LIST_SUCCESS = "FAVORITE_GET_LIST_SUCCESS";
export const FAVORITE_GET_LIST_ERROR = "FAVORITE_GET_LIST_ERROR";

export const ACCOUNT_GET_INFO = "ACCOUNT_GET_INFO";
export const ACCOUNT_GET_INFO_SUCCESS = "ACCOUNT_GET_INFO_SUCCESS";
export const ACCOUNT_GET_INFO_ERROR = "ACCOUNT_GET_INFO_ERROR";
export const ACCOUNT_SAVE = "ACCOUNT_SAVE";
export const ACCOUNT_SAVE_SUCCESS = "ACCOUNT_SAVE_SUCCESS";
export const ACCOUNT_SAVE_ERROR = "ACCOUNT_SAVE_ERROR";

export const CONFIG_GET = "CONFIG_GET";
export const CONFIG_GET_SUCCESS = "CONFIG_GET_SUCCESS";
export const CONFIG_GET_ERROR = "CONFIG_GET_ERROR";

export const ORDERS_GET_LIST = "ORDERS_GET_LIST";
export const ORDERS_GET_LIST_SUCCESS = "ORDERS_GET_LIST_SUCCESS";
export const ORDERS_GET_LIST_ERROR = "ORDERS_GET_LIST_ERROR";

export const ORDER_GET_INFO = "ORDER_GET_INFO";
export const ORDER_GET_INFO_SUCCESS = "ORDER_GET_INFO_SUCCESS";
export const ORDER_GET_INFO_ERROR = "ORDER_GET_INFO_ERROR";

export const SPECIAL_GET_LIST = "SPECIAL_GET_LIST";
export const SPECIAL_GET_LIST_SUCCESS = "SPECIAL_GET_LIST_SUCCESS";
export const SPECIAL_GET_LIST_ERROR = "SPECIAL_GET_LIST_ERROR";

export const CATALOGS_GET_LIST = "CATALOGS_GET_LIST";
export const CATALOGS_GET_LIST_SUCCESS = "CATALOGS_GET_LIST_SUCCESS";
export const CATALOGS_GET_LIST_ERROR = "CATALOGS_GET_LIST_ERROR";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./basket/actions";
export * from "./favorite/actions";
export * from "./orders/actions";
export * from "./special/actions";
export * from "./catalogs/actions";
