import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
    CATALOGS_GET_LIST, getAccountInfo, getConfig
} from '../actions'

import {
    getCatalogsListSuccess,
    getCatalogsListError
} from './actions'
import axios from "axios"
import {apiPath} from "../../constants/defaultValues"
import {toUrlSearch} from "../../helpers/Utils";

const apiUrl = apiPath + "/json/product-catalogs.do/"

const getCatalogsListRequest = (pageSize,currentPage,advSearch) => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const lang = localStorage.getItem('currentLanguage');
    let advSearchUrl = (advSearch)?toUrlSearch(advSearch):'';
    if(advSearchUrl!=='') advSearchUrl = '&'+advSearchUrl;
    return axios({
        method: "get",
        url: `${apiUrl}?user=${user_id}&lang=${lang}&token=${token}&pageSize=${pageSize}&currentPage=${currentPage}${advSearchUrl}`
    })
}

function* getCatalogsListItems({payload}) {
    try {
        const response = yield call(getCatalogsListRequest,payload.pageSize, payload.currentPage,payload.advSearch)
        yield put(getCatalogsListSuccess(response))
        yield put(getConfig());
        yield put(getAccountInfo());
    } catch (error) {
        yield put(getCatalogsListError(error))
    }
}

export function* watchGetCatalogsList() {
    yield takeEvery(CATALOGS_GET_LIST, getCatalogsListItems)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetCatalogsList)
    ])
}
