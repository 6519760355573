import {
    BASKET_ADD,
    BASKET_ADD_ITEM_SUCCESS,
    BASKET_ADD_ITEM_ERROR,
    BASKET_GET_LIST_SUCCESS,
    BASKET_GET_LIST_ERROR,
    BASKET_GET_LIST,
    BASKET_REMOVE,
    BASKET_REMOVE_ITEM_SUCCESS,
    BASKET_REMOVE_ITEM_ERROR,
    BASKET_CHANGE,
    BASKET_CHANGE_ITEM_SUCCESS,
    BASKET_CHANGE_ITEM_ERROR,
    TO_ORDER_ADD,
    TO_ORDER_ADD_ITEM_SUCCESS,
    TO_ORDER_ADD_ITEM_ERROR,
    TO_BASKET_ADD,
    TO_BASKET_ADD_ITEM_SUCCESS,
    TO_BASKET_ADD_ITEM_ERROR,
    CHANGE_DISCOUNT,
    CHANGE_DISCOUNT_SUCCESS,
    CHANGE_DISCOUNT_ERROR,
    CHANGE_ALL_DISCOUNT,
    CHANGE_ALL_DISCOUNT_SUCCESS,
    CHANGE_ALL_DISCOUNT_ERROR
} from '../actions'


export const getBasketList = () => ({
  type: BASKET_GET_LIST
})

export const getBasketListSuccess = (items) => ({
  type: BASKET_GET_LIST_SUCCESS,
  payload: items
})

export const getBasketListError = (error) => ({
  type: BASKET_GET_LIST_ERROR,
  payload: error
})

export const changeBasket = (num, id,orderId) => ({
  type: BASKET_CHANGE,
  payload: {num, id,orderId}
})

export const changeDiscount = (value, id,orderId) => ({
  type: CHANGE_DISCOUNT,
  payload: {value, id,orderId}
})
export const changeDiscountSuccess = (items) => ({
  type: CHANGE_DISCOUNT_SUCCESS,
  payload: items
})

export const changeDiscountError = (error) => ({
  type: CHANGE_DISCOUNT_ERROR,
  payload: error
})
export const changeAllDiscount = (value) => ({
  type: CHANGE_ALL_DISCOUNT,
  payload: {value}
})
export const changeAllDiscountSuccess = (items) => ({
  type: CHANGE_ALL_DISCOUNT_SUCCESS,
  payload: items
})

export const changeAllDiscountError = (error) => ({
  type: CHANGE_ALL_DISCOUNT_ERROR,
  payload: error
})
export const changeBasketItemSuccess = (items) => ({
  type: BASKET_CHANGE_ITEM_SUCCESS,
  payload: items
})

export const changeBasketItemError = (error) => ({
  type: BASKET_CHANGE_ITEM_ERROR,
  payload: error
})

export const removeBasket = (item,orderId) => ({
  type: BASKET_REMOVE,
  payload:  {item,orderId}
})

export const removeBasketItemSuccess = (items) => ({
  type: BASKET_REMOVE_ITEM_SUCCESS,
  payload: items
})

export const removeBasketItemError = (error) => ({
  type: BASKET_REMOVE_ITEM_ERROR,
  payload: error
})

export const addBasket = (item, quantity) => ({
  type: BASKET_ADD,
  payload: {item, quantity}
})

export const addBasketItemSuccess = (items) => ({
  type: BASKET_ADD_ITEM_SUCCESS,
  payload: items
})

export const addBasketItemError = (error) => ({
  type: BASKET_ADD_ITEM_ERROR,
  payload: error
})


export const addToBasket = (item) => ({
    type: TO_BASKET_ADD,
    payload: item
});

export const addToBasketItemSuccess = (items) => ({
    type: TO_BASKET_ADD_ITEM_SUCCESS,
    payload: items
});

export const addToBasketItemError = (error) => ({
    type: TO_BASKET_ADD_ITEM_ERROR,
    payload: error
});

export const addToOrder = (item) => ({
  type: TO_ORDER_ADD,
  payload: item
})

export const addToOrderItemSuccess = (items) => ({
  type: TO_ORDER_ADD_ITEM_SUCCESS,
  payload: items
})

export const addToOrderItemError = (error) => ({
  type: TO_ORDER_ADD_ITEM_ERROR,
  payload: error
})
