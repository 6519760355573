import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
    ORDER_GET_INFO,
    ORDERS_GET_LIST
} from '../actions'

import {
    getOrdersListSuccess,
    getOrdersListError,
    getOrderInfoSuccess,
    getOrderInfoError
} from './actions'
import axios from "axios"
import {apiPath} from "../../constants/defaultValues"

const apiUrl = apiPath + "/json/product-orders.do/"
const apiOrderInfoUrl = apiPath + "/json/product-order-info.do/"

const getOrdersListRequest = (currentPage) => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
  const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiUrl}?user=${user_id}&lang=${lang}&token=${token}&currentPage=${currentPage}`
    })
}

function* getOrdersListItems({payload}) {
    try {
        const response = yield call(getOrdersListRequest, payload.currentPage)
        yield put(getOrdersListSuccess(response))
    } catch (error) {
        yield put(getOrdersListError(error))
    }
}


const getOrderInfoRequest = (id) => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const lang = localStorage.getItem('currentLanguage');
    return axios({
        method: "get",
        url: `${apiOrderInfoUrl}?id=${id}&lang=${lang}&user=${user_id}&token=${token}`
    })
}

function* getOrderInfo({payload}) {
    try {
        const response = yield call(getOrderInfoRequest, payload.id)
        yield put(getOrderInfoSuccess(response))
    } catch (error) {
        yield put(getOrderInfoError(error))
    }
}


export function* watchGetOrdersList() {
    yield takeEvery(ORDERS_GET_LIST, getOrdersListItems)
}

export function* watchOrderInfo() {
    yield takeEvery(ORDER_GET_INFO, getOrderInfo)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetOrdersList),
        fork(watchOrderInfo),
    ])
}
