import {
    CATALOGS_GET_LIST,
    CATALOGS_GET_LIST_SUCCESS,
    CATALOGS_GET_LIST_ERROR
} from '../actions'

const INIT_STATE = {
	catalogItems:null,
	loading:false
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {

        case CATALOGS_GET_LIST:
            return { ...state, loading: false }

        case CATALOGS_GET_LIST_SUCCESS:
            return { ...state, loading: true, catalogItems: action.payload }

        case CATALOGS_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }

		default: return { ...state }
	}
}
