import {
  ORDERS_GET_LIST,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_GET_LIST_ERROR,
  ORDER_GET_INFO,
  ORDER_GET_INFO_SUCCESS,
  ORDER_GET_INFO_ERROR
} from '../actions'


export const getOrdersList = (items) => ({
    type: ORDERS_GET_LIST,
    payload: items
})

export const getOrdersListSuccess = (items) => ({
    type: ORDERS_GET_LIST_SUCCESS,
    payload: items
})

export const getOrdersListError = (error) => ({
    type: ORDERS_GET_LIST_ERROR,
    payload: error
})

export const getOrderInfo = (id) => ({
    type: ORDER_GET_INFO,
    payload: {id}
})

export const getOrderInfoSuccess = (items) => ({
    type: ORDER_GET_INFO_SUCCESS,
    payload: items
})

export const getOrderInfoError = (error) => ({
    type: ORDER_GET_INFO_ERROR,
    payload: error
})


