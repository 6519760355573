import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
    FAVORITE_ADD,
    FAVORITE_GET_LIST,
    ACCOUNT_GET_INFO,
    ACCOUNT_SAVE,
    CONFIG_GET
} from '../actions';

import {
    addFavoriteItemSuccess,
    addFavoriteItemError,
    getFavoriteListSuccess,
    getFavoriteListError,
    getAccountInfoSuccess,
    getAccountInfoError,
    doSaveAccountSuccess,
    doSaveAccountError,
    getAccountInfo, getConfigSuccess, getConfigError
} from './actions';

import axios from "axios";
import {apiPath} from "../../constants/defaultValues";

const apiUrl = apiPath + "/json/product-add-favorite.do/";
const apiFavoriteUrl = apiPath + "/json/product-favorite.do/";
const apiAccountUrl = apiPath + "/json/product-account.do/";
const apiConfigUrl = apiPath + "/json/product-all-config.do/";
const apiSaveAccountUrl = apiPath + "/json/product-account-save.do/";

const addFavoriteRequest = (item) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    return axios({
        method: "get",
        url: `${apiUrl}?id=${item}&user=${user_id}&token=${token}`
    });
};

function* addFavoriteItem({payload}) {

    try {
        const response = yield call(addFavoriteRequest, payload.id);

        yield put(addFavoriteItemSuccess(response));
    } catch (error) {
        yield put(addFavoriteItemError(error));
    }
}

function toUrlSearch(hipChatSettings) {
    return Object.keys(hipChatSettings).map(function (prop) {
        return [prop, hipChatSettings[prop]].map(encodeURIComponent).join("=");
    }).join("&");
}

const getFavoriteListRequest = (pageSize, currentPage, orderBy, orderTypeBy, search, advSearch, type) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
  const lang = localStorage.getItem('currentLanguage');
    let advSearchUrl = (advSearch) ? toUrlSearch(advSearch) : '';
    if (advSearchUrl !== '') advSearchUrl = '&' + advSearchUrl;

    return axios({
        method: "get",
        url: `${apiFavoriteUrl}?user=${user_id}&lang=${lang}&token=${token}&pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}&orderTypeBy=${orderTypeBy}&search=${search}&type=${type}${advSearchUrl}`
    });
};

function* getFavoriteListItems({payload}) {
    try {
        const response = yield call(getFavoriteListRequest, payload.pageSize, payload.currentPage, payload.orderBy, payload.orderTypeBy, payload.search, payload.advSearch, 1);
        yield put(getFavoriteListSuccess(response));
    } catch (error) {
        yield put(getFavoriteListError(error));
    }
}


const getAccountInfoRequest = () => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('currentLanguage');
    const typePage = localStorage.getItem('typepage');
    const data = JSON.parse(localStorage.getItem('state_start'));
    const {category} = data;
    return axios({
        method: "get",
        url: `${apiAccountUrl}?user=${user_id}&token=${token}&lang=${lang}&cat=${category}&typePage=${typePage}`
    });
};

const getConfigRequest = () => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('currentLanguage');
    const data = JSON.parse(localStorage.getItem('state_start'));
    const {category} = data;
    return axios({
        method: "get",
        url: `${apiConfigUrl}?user=${user_id}&lang=${lang}&token=${token}&cat=${category}`
    });
};


const getAccountInfoTimeout =()=> {
    let myVar = setInterval(() => { getAccountInfoRequest(); clearInterval(myVar);}, 100);

}

function* getAccountInfoData() {
    try {
      const response = yield call(getAccountInfoRequest);
      yield put(getAccountInfoSuccess(response));
    } catch (error) {
        yield put(getAccountInfoError(error));
    }
}

function* getConfigData() {
    try {
        const response = yield call(getConfigRequest);
        yield put(getConfigSuccess(response));
    } catch (error) {
        yield put(getConfigError(error));
    }
}

const doSaveAccountRequest = (lastname, firstname, requisites, logo, email, showPrice,showPriceOnline,showPriceSupplier, roundingNumber, valuta,phone) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');

    let formData = new FormData();

    /*
        Add the form data we need to submit
    */
    formData.append('logo', logo);
    formData.append('user', user_id);
    formData.append('lastname', lastname);
    formData.append('firstname', firstname);
    formData.append('email', email);
    formData.append('showPrice', showPrice);
    formData.append('showPriceOnline', showPriceOnline);
    formData.append('showPriceSupplier', showPriceSupplier);
    formData.append('requisites', requisites);
    formData.append('roundingNumber', roundingNumber);
    formData.append('valuta', valuta);
    formData.append('token', token);
    formData.append('phone', phone);
    return axios({
        method: "post",
        headers: {'Content-Type': 'multipart/form-data'},
        url: `${apiSaveAccountUrl}`,
        data: formData
    });
};

function* doSaveAccount({payload}) {
    try {

        const response = yield call(
            doSaveAccountRequest,
            payload.item.lastname,
            payload.item.firstname,
            payload.item.requisites,
            payload.item.logo,
            payload.item.email,
            payload.item.showPrice,
            payload.item.showPriceOnline,
            payload.item.showPriceSupplier,
            payload.item.roundingNumber,
            payload.item.valuta,
            payload.item.phone
        );

        yield put(doSaveAccountSuccess(response));
      //  localStorage.removeItem('user_id');
        yield put(getAccountInfo());
        window.location.reload();
    } catch (error) {
        yield put(doSaveAccountError(error));
    }
}

export function* watchGetFavoriteList() {
    yield takeEvery(FAVORITE_GET_LIST, getFavoriteListItems);
}

export function* watchGetAccountInfo() {
    yield takeEvery(ACCOUNT_GET_INFO, getAccountInfoData);
}

export function* watchGetConfig() {
    yield takeEvery(CONFIG_GET, getConfigData);
}

export function* watchAddFavoriteItem() {
    yield takeEvery(FAVORITE_ADD, addFavoriteItem);
}

export function* watchDoSaveAccount() {
    yield takeEvery(ACCOUNT_SAVE, doSaveAccount);
}

export default function* rootSaga() {
    yield all([
        fork(watchDoSaveAccount),
        fork(watchAddFavoriteItem),
        fork(watchGetFavoriteList),
        fork(watchGetAccountInfo),
        fork(watchGetConfig),
    ]);
}
