import {
    BASKET_ADD,
    BASKET_ADD_ITEM_SUCCESS,
    BASKET_ADD_ITEM_ERROR,
    BASKET_GET_LIST,
    BASKET_GET_LIST_SUCCESS,
    BASKET_GET_LIST_ERROR,
    BASKET_REMOVE,
    BASKET_REMOVE_ITEM_SUCCESS,
    BASKET_REMOVE_ITEM_ERROR,
    BASKET_CHANGE,
    BASKET_CHANGE_ITEM_SUCCESS,
    BASKET_CHANGE_ITEM_ERROR,
    TO_ORDER_ADD,
    TO_ORDER_ADD_ITEM_SUCCESS,
    TO_ORDER_ADD_ITEM_ERROR,
    CHANGE_DISCOUNT,
    CHANGE_DISCOUNT_SUCCESS,
    CHANGE_DISCOUNT_ERROR,
    CHANGE_ALL_DISCOUNT,
    CHANGE_ALL_DISCOUNT_SUCCESS,
    CHANGE_ALL_DISCOUNT_ERROR, TO_BASKET_ADD, TO_BASKET_ADD_ITEM_SUCCESS, TO_BASKET_ADD_ITEM_ERROR
} from '../actions'

const INIT_STATE = {
    basketItem: null,
    loading: false,
    toOrder: false,
    orderId: null,
    toSave: 0,
    addBasketEvent: '',
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case BASKET_GET_LIST:
            return {...state, loading: false, addBasketEvent: ''}

        case BASKET_GET_LIST_SUCCESS:
            return {...state, loading: true, basketItem: action.payload, addBasketEvent: ''}

        case BASKET_GET_LIST_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: ''}

        case BASKET_ADD:
            return {...state, loading: false, addBasketEvent: ''}

        case BASKET_ADD_ITEM_SUCCESS:
            return {...state, loading: true, toOrder: false, addBasketEvent: 'addSuccess'}

        case BASKET_ADD_ITEM_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'addError'}

        case BASKET_REMOVE:
            return {...state, loading: false, addBasketEvent: 0}

        case BASKET_REMOVE_ITEM_SUCCESS:
            return {...state, loading: true, basketItem: action.payload, addBasketEvent: 'removeSuccess'}

        case BASKET_REMOVE_ITEM_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'removeError'}

        case BASKET_CHANGE:
            return {...state, loading: false, addBasketEvent: 0}

        case BASKET_CHANGE_ITEM_SUCCESS:
            return {...state, loading: true, addBasketEvent: 'changeSuccess'}

        case BASKET_CHANGE_ITEM_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'changeError'}

        case CHANGE_DISCOUNT:
            return {...state, loading: false, addBasketEvent: ''}

        case CHANGE_DISCOUNT_SUCCESS:
            return {...state, loading: true, addBasketEvent: 'discountSuccess'}

        case CHANGE_DISCOUNT_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'discountError'}

        case CHANGE_ALL_DISCOUNT:
            return {...state, loading: false, addBasketEvent: ''}

        case CHANGE_ALL_DISCOUNT_SUCCESS:
            return {...state, loading: true, addBasketEvent: 'discountSuccess'}

        case CHANGE_ALL_DISCOUNT_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'discountError'}

        case TO_ORDER_ADD:
            return {...state, loading: false, addBasketEvent: ''}

        case TO_ORDER_ADD_ITEM_SUCCESS:
            return {
                ...state,
                loading: true,
                orderId: action.payload.data.data.order_id,
                toOrder: true,
                toSave: action.payload.data.data.save,
                addBasketEvent: 'toOrderSuccess'
            }

        case TO_ORDER_ADD_ITEM_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'toOrderError'}

        case TO_BASKET_ADD:
            return {...state, loading: false, addBasketEvent: ''}

        case TO_BASKET_ADD_ITEM_SUCCESS:
            return {
                ...state,
                loading: true,
                addBasketEvent: 'addSuccess'
            };

        case TO_BASKET_ADD_ITEM_ERROR:
            return {...state, loading: true, error: action.payload, addBasketEvent: 'addError'}

        default:
            return {...state, addBasketEvent: ''}
    }
}
